import { INSTRUMENT_SYMBOL_REGEXES } from '../constants/REGEX_PATTERNS.ts';

/**
 * Extracts securities from a string.
 *
 * @param content - The string from which securities need to be extracted.
 * @returns An array of unique securities extracted from the string.
 */
export function extractSecuritiesFromString(content: string) {
	const symbolRegex = INSTRUMENT_SYMBOL_REGEXES.withoutWhitespace;
	const symbolMatches = content.match(symbolRegex)?.map((match) => match.slice(1));
	const dedupedSymbols = [...new Set(symbolMatches)];
	return dedupedSymbols;
}
