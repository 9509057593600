<script lang="ts">
	// Props...
	export let tags: string[] = [];
	export let instruments: string[] = [];
</script>

<ul data-testid="tag-preview" class="flex flex-wrap gap-2">
	<!-- eslint-disable-next-line svelte/require-each-key -->
	{#each instruments as security}
		<li class="variant-filled-primary badge rounded-md p-2">
			<span>
				${security}
			</span>
		</li>
	{/each}
	<!-- eslint-disable-next-line svelte/require-each-key -->
	{#each tags as tag}
		<li class="variant-filled badge rounded-md p-2">
			<span>
				#{tag}
			</span>
		</li>
	{/each}
</ul>
