import { TAG_REGEXES } from '../constants/REGEX_PATTERNS.ts';

/**
 * Extracts unique tags from a string.
 *
 * @param content - The string from which to extract tags.
 * @returns An array of unique tags extracted from the string.
 */
export function extractTagsFromString(content: string) {
	const tagRegex = TAG_REGEXES.withoutWhitespace;
	const tagMatches = content.match(tagRegex)?.map((match) => match.slice(1));
	const dedupedTags = [...new Set(tagMatches)];
	return dedupedTags;
}
