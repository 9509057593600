<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { fade } from 'svelte/transition';

	export let loading = true;
	export let classes: CssClasses = '';
</script>

{#if loading}
	<div
		class="
        flex
        h-full
        w-full
        animate-pulse
        items-center
        justify-center
        bg-component
        transition-all
        duration-[3000ms]
        ease-in
        rounded-token
        {classes}
        "
		in:fade
	>
		<slot />
	</div>
{:else}
	<slot />
{/if}
