<!-- 
	@component
	
	NEW POST MODAL

	A modal for creating a new post.
-->
<script lang="ts">
	import ModalCloseButton from '../../ModalCloseButton.svelte';
	import StickyModalWrapper from '../../StickyModalWrapper.svelte';
	import NewPostForm from './NewPostForm.svelte';
</script>

<StickyModalWrapper
	classes="!p-0 overflow-hidden"
	testid="new-post-modal"
	useHeader
	width="w-modal"
>
	<!-- TITLE -->
	<svelte:fragment slot="header-lead">
		<div class="flex w-full px-4">
			<h3 class="flex justify-center text-xl font-bold">Create a New Post</h3>
		</div>
	</svelte:fragment>

	<svelte:fragment slot="header-trail">
		<ModalCloseButton />
	</svelte:fragment>

	<!-- CONTENT -->
	<div class="flex h-full w-full flex-col items-center justify-center">
		<NewPostForm focusOnMount />
	</div>
</StickyModalWrapper>
