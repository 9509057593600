<!-- 
	@component
	
	NEW POST EDITOR

	@prop activeDraft - The active draft of the new post.
	@prop view - The view of the new post form.
	@prop focusOnMount - Whether or not to focus on the editor when it mounts.
	@prop errorMessage - The error message to display.
-->
<script lang="ts">
	import {
		extractSecuritiesFromString,
		extractTagsFromString,
		transformUserMarkdown
	} from '@tickrr/lib/utils';
	import { createEventDispatcher } from 'svelte';

	import type { NewPost } from '../../../../../idb';
	import type { NewPostView } from './NewPostForm.svelte';

	import { tippy } from '../../../../../actions';
	import ImageUploader from '../../../elements/images/ImageUploader.svelte';
	import UserAvatar from '../../../elements/images/UserAvatar.svelte';
	import MarkdownEditor, {
		ACTION_ICON_CLASSES
	} from '../../../elements/inputs/MarkdownEditor.svelte';
	import Markdown from '../../../elements/markdown/Markdown.svelte';
	import TagPreview from './TagPreview.svelte';

	const dispatch = createEventDispatcher<{ saveForLater: NewPost }>();

	// Props...
	export let activeDraft: NewPost;
	export let view: NewPostView;
	export let focusOnMount: boolean;
	export let errorMessage: null | string = null;

	let editorEl: HTMLTextAreaElement;

	function dispatchSaveForLater() {
		dispatch('saveForLater', activeDraft);
	}

	$: activeDraft.tags = extractTagsFromString(activeDraft.content);
	$: activeDraft.securities = extractSecuritiesFromString(activeDraft.content);
</script>

<MarkdownEditor {errorMessage} {focusOnMount} bind:el={editorEl} bind:content={activeDraft.content}>
	<!-- TAB AREA -->
	<svelte:fragment slot="tab-trail">
		<button
			type="button"
			class="btn ml-auto text-secondary-500 hover:underline"
			on:click={() => (view = 'DRAFTS')}
		>
			<span> View drafts </span>
		</button>
	</svelte:fragment>

	<!-- ADDITIONAL INPUT (FOR TITLE) -->
	<svelte:fragment slot="title">
		<div class="flex pt-2">
			<div class="pl-[18px] pt-2">
				<UserAvatar height="h-10" width="w-10" />
			</div>
			<label class="w-full">
				<div class="sr-only">
					<span>Title</span>
				</div>
				<input
					id="new-post-title"
					placeholder="Title"
					type="text"
					class="input !rounded-none !border-0 !bg-transparent !ring-0"
					bind:value={activeDraft.title}
				/>
			</label>
		</div>
	</svelte:fragment>

	<!-- ADDITIONAL INPUT (FOR IMAGES) -->
	<svelte:fragment slot="additional-draft-panel-content">
		<div class="space-y-4 p-4">
			<ImageUploader bind:file={activeDraft.image} />
			{#if activeDraft.securities.length > 0 || activeDraft.tags.length > 0}
				<TagPreview instruments={activeDraft.securities} tags={activeDraft.tags} />
			{/if}
		</div>
	</svelte:fragment>

	<!-- ADDITIONAL ACTIONS -->
	<svelte:fragment slot="actions-trail">
		<button
			type="button"
			class={ACTION_ICON_CLASSES}
			on:click={dispatchSaveForLater}
			use:tippy={{ content: 'Save draft', placement: 'top' }}
		>
			<iconify-icon icon="solar:diskette-outline"></iconify-icon>
			<span class="sr-only"> Save draft </span>
		</button>
		<slot name="actions-trail" />
	</svelte:fragment>

	<!-- PREVIEW TAB -->
	<svelte:fragment slot="preview">
		<div data-testid="new-post__preview-tab" class="h-full min-h-[315px] p-4">
			{#if activeDraft.title !== ''}
				<Markdown source={transformUserMarkdown(`# ${activeDraft.title}`)} />
			{:else}
				<p class="text-xl italic">No title provided.</p>
			{/if}

			<div class="my-4">
				<TagPreview instruments={activeDraft.securities} tags={activeDraft.tags} />
			</div>

			<Markdown
				source={transformUserMarkdown(activeDraft.content, { config: 'POST' }) ||
					'No content provided.'}
			/>

			{#if activeDraft.image}
				<div
					data-testid="fiv__image-container"
					class="relative mb-12 mt-4 aspect-video max-w-md overflow-hidden rounded-token tablet:mb-16 tablet:mt-10"
				>
					<img
						alt="Post thumbnail"
						src={activeDraft.image instanceof File
							? URL.createObjectURL(activeDraft.image)
							: activeDraft.image}
						class="h-full w-full"
					/>
				</div>
			{/if}
		</div>
	</svelte:fragment>
</MarkdownEditor>
