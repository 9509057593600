<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	import type { NewPost } from '../../../../../idb';
	import type { NewPostView } from './NewPostForm.svelte';

	import LoaderTrio from '../../../elements/loaders/LoaderTrio.svelte';

	const dispatch = createEventDispatcher<{
		deleteDraft: { draftId: number };
	}>();

	export let view: NewPostView;
	export let drafts: NewPost[] | undefined = undefined;
	export let updateActiveDraft: (draft: NewPost) => void;

	function dispatchDelete(draftId: number) {
		dispatch('deleteDraft', { draftId });
	}
</script>

<header class="flex h-[57px] items-center justify-between border-b border-base">
	<h3 class="h4 ml-4 font-bold">Drafts</h3>

	<button
		type="button"
		class="btn ml-auto text-secondary-500 hover:underline"
		on:click={() => (view = 'EDITOR')}
	>
		<span> Back to editor </span>
	</button>
</header>

{#if drafts === undefined}
	<div class="flex h-full min-h-[373px] flex-col items-center justify-center p-4 text-center">
		<LoaderTrio />
	</div>
{:else if drafts?.length === 0}
	<div class="flex h-full min-h-[373px] flex-col items-center justify-center p-4 text-center">
		<p>No drafts found.</p>
	</div>
{:else}
	<ul data-testid="new-post__draft-list" class="max-h-[400px] w-full overflow-y-scroll">
		{#each drafts as draft (draft.draftId)}
			<li class="group flex h-20 w-full">
				<!-- DRAFT BODY -->
				<button
					type="button"
					class="flex w-full gap-x-4 p-2 text-left group-hover:variant-filled-surface"
					on:click={() => updateActiveDraft(draft)}
				>
					<!-- IMAGE -->
					{#if draft.image}
						<div
							class="h-16 w-24 !shrink-0 overflow-hidden rounded-token group-hover:border"
						>
							<img
								alt={draft.title}
								src={draft.image instanceof File
									? URL.createObjectURL(draft.image)
									: draft.image}
								class="object-cover"
							/>
						</div>
					{:else}
						<div
							class="flex h-16 w-24 !shrink-0 flex-col items-center justify-center bg-component rounded-token group-hover:border"
						>
							<iconify-icon icon="solar:camera-bold"></iconify-icon>
							<span class="text-center text-xs"> No image provided </span>
						</div>
					{/if}

					<!-- CONTENT -->
					<div class="w-full">
						<p class="line-clamp-1 font-bold text-surface-50">
							{draft.title || 'No title.'}
						</p>
						<p class="line-clamp-2">
							{draft.content || 'No content.'}
						</p>
					</div>
				</button>

				<!-- DELETE BUTTON -->
				<div
					class="flex h-full w-20 items-center justify-center group-hover:variant-filled-surface"
				>
					<button
						type="button"
						class="btn-icon rounded-md hover:text-red-500"
						on:click={() => dispatchDelete(draft.draftId)}
					>
						<iconify-icon icon="solar:trash-bin-minimalistic-outline"></iconify-icon>
						<span class="sr-only">Delete draft</span>
					</button>
				</div>
			</li>
		{/each}
	</ul>
{/if}
