<!-- 
    @component
    
    FORM
    
    A wrapper for form elements.

    @prop {string} padding - The padding to apply to the form.
    @prop {string} classes - The form's classes.

 -->
<script lang="ts">
	export let padding: string = 'p-4 tablet:p-8';
	export let gap: string = 'gap-y-4';
	export let classes: string = '';
</script>

<form
	class="flex w-full flex-col {gap} text-surface-100 {padding} {classes}"
	{...$$restProps}
	on:submit|preventDefault
	on:change
>
	<slot />
</form>
