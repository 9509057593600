import type { NewPost } from '../../../../../idb';

export const generateDefaultPost: (draftId?: number) => NewPost = (draftId) => {
	return {
		content: '',
		draftId: draftId ?? Math.floor(Math.random() * 9000000000) + 1000000000,
		image: null,
		securities: [],
		tags: [],
		title: ''
	};
};
