<!-- 
	@component

	NEW POST FORM
	
	A form for creating a new post.

	@prop {boolean} canSubmitPost - Whether or not the post can be submitted.
	@prop {boolean} isLoading - Whether or not the post is loading.
	@prop {function} handleSubmitClick - The handler for when the submit button is clicked.
	@prop {string} padding - The padding to apply to the form.
	@prop {boolean} withMessage - Whether or not to include a message at the top of the form.
	@prop {string} editorClasses - The classes to apply to the editor.

-->
<script context="module" lang="ts">
	export type NewPostView = 'DRAFTS' | 'EDITOR';
</script>

<script lang="ts">
	import { page } from '$app/stores';
	import { getDrawerStore, getModalStore, getToastStore } from '@skeletonlabs/skeleton';
	import { createMutation, useQueryClient } from '@tanstack/svelte-query';
	import { uploadPostImageToSupabase } from '@tickrr/db/supabase';
	import { logger } from '@tickrr/lib/logger';
	import { transformUserMarkdown } from '@tickrr/lib/utils';
	import { trpc } from '@tickrr/trpc/client';
	import { onDestroy, onMount } from 'svelte';

	import { SignedOut, clerk, constructAuthRedirect, openClerkModal } from '../../../../../auth';
	import { type NewPost, useIDB } from '../../../../../idb/index.ts';
	import { getStores } from '../../../../../stores';
	import { Form, SubmitButton } from '../../../elements';
	import LoadingOverlay from '../../../elements/loaders/LoadingOverlay.svelte';
	import Logo from '../../../modules/app/Logo.svelte';
	import { openFIVModal } from '../../../modules/feed-item-viewer/index.ts';
	import DraftList from './NewPost.Drafts.svelte';
	import NewPostEditor from './NewPost.Editor.svelte';
	import { generateDefaultPost } from './common.ts';

	const queryClient = useQueryClient();
	const modalStore = getModalStore();
	const drawerStore = getDrawerStore();
	const toastStore = getToastStore();
	const { idbStore, isExtensionStore, sbStore } = getStores([
		'idbStore',
		'sbStore',
		'isExtensionStore'
	]);

	// Props (used by parent)...
	export let isLoading: boolean = false;
	export let padding: string = 'p-0';
	export let withMessage: boolean = false;
	export let focusOnMount: boolean = false;
	export let editorClasses = '';

	let view: NewPostView = 'EDITOR';
	let drafts: NewPost[] | undefined = undefined;
	let errorMessage: null | string = null;
	let activeDraft: NewPost = generateDefaultPost();

	const handleUnauthorizedClick = async () => {
		modalStore.clear();
		await openClerkModal({
			clerk: $clerk,
			redirectUrl: constructAuthRedirect('/new'),
			type: 'SIGN_IN'
		});
	};

	const triggerErrorMessage = (message: string) => {
		errorMessage = message;
	};

	function updateActiveDraft(draft: NewPost) {
		activeDraft = draft;
		view = 'EDITOR';
	}

	async function saveDraft(draft: NewPost) {
		logger.debug({ draft }, 'Saving draft...');
		await $idbStore.put('newPosts', draft);
		drafts = await $idbStore.getAll('newPosts');
	}

	async function saveForLater() {
		logger.debug({ savedDraft: activeDraft }, 'Saving for later...');
		await saveDraft(activeDraft);
		activeDraft = generateDefaultPost();
	}

	async function deleteDraft(draftId: number) {
		logger.debug({ draftId }, 'Deleting draft...');
		await $idbStore.delete('newPosts', draftId);
		drafts = await $idbStore.getAll('newPosts');
	}

	onMount(async () => {
		if (!$idbStore) {
			idbStore.set(await useIDB());
		}

		drafts = await $idbStore.getAll('newPosts');
		activeDraft = drafts?.[0] || activeDraft;
	});

	onDestroy(async () => {
		await saveDraft(activeDraft);
	});

	const newPostMutation = createMutation({
		mutationFn: async (ad: NewPost) => {
			if (!$sbStore) {
				throw new Error('Supabase store not found.');
			}

			const { post } = await trpc($page).post.insert.mutate({
				content: transformUserMarkdown(ad.content, { config: 'POST' }),
				hasImage: !!ad.image,
				securities: ad.securities,
				tags: ad.tags,
				title: ad.title
			});

			if (!ad.image) {
				return {
					imagePath: null,
					post
				};
			}

			const imageRes = await uploadPostImageToSupabase({
				feedItemId: post.id,
				file: ad.image,
				supabase: $sbStore
			});

			if (imageRes.isErr()) {
				logger.error('Error uploading image to Supabase', imageRes.error);
				const deleteRes = await trpc($page).post.delete.mutate(post.id);
				logger.warn('Deleted post due to image upload error', deleteRes);
				throw new Error(imageRes.error.message);
			}

			return {
				imagePath: imageRes.value,
				key: ad.draftId, // Used to delete draft.
				post
			};
		},
		onError: () => {
			// Note: how we do *not* close the form if an error occurs.
			triggerErrorMessage('Something went wrong. Please try again.');
		},
		onSuccess: async (data) => {
			toastStore.trigger({
				classes: 'toast-success',
				message: 'Post created successfully!'
			});

			// Clear state...
			activeDraft.title = '';
			activeDraft.content = '';
			activeDraft.image = null;
			activeDraft.tags = [];
			activeDraft.securities = [];

			modalStore.close();
			drawerStore.close();

			// Open viewer...
			openFIVModal({ isExtension: $isExtensionStore, item: data.post, modalStore });

			// Clear draft...
			if (data.key) {
				await $idbStore.delete('newPosts', data.key);
			}

			// Update onboarding checklist (if needed)...
			if (!$page.data.profile?.is_onboarding_completed) {
				queryClient.refetchQueries(['onboardingChecklist']);
			}
		}
	});

	$: isAuthenticated = !!$page.data.profile;
	$: newPostTitleWordCount = activeDraft.title.split(' ').length - 1;
	$: newPostBodyWordCount = activeDraft.content.split(' ').length - 1;
	$: newPostTitleIsValid = activeDraft.title.trim().length > 0 && newPostTitleWordCount <= 50;
	$: newPostBodyIsValid = activeDraft.content.trim().length > 0 && newPostBodyWordCount <= 500;
	$: newPostTagsIsValid = activeDraft.tags.length <= 10;
	$: isLoading = $newPostMutation.isLoading;

	$: handleSubmitClick = () => {
		errorMessage = null;

		if (!isAuthenticated) {
			triggerErrorMessage(
				'Only verified users can submit a post. Please login or signup to continue.'
			);
			return;
		}

		if (!newPostTitleIsValid) {
			triggerErrorMessage('Please enter a valid title for your post.');
			return;
		}

		if (!newPostBodyIsValid) {
			triggerErrorMessage('Please enter a valid body for your post.');
			return;
		}

		if (!newPostTagsIsValid) {
			triggerErrorMessage('Please only submit 10 or less tags.');
			return;
		}

		if ($newPostMutation.isLoading) {
			triggerErrorMessage('Please wait for your post to finish submitting.');
			return;
		}

		$newPostMutation.mutate(activeDraft);
	};
</script>

<!-- UNAUTHORIZED MESSAGE -->
<SignedOut>
	<div class="variant-filled flex w-full items-center justify-between gap-x-6 p-4">
		<p class="flex gap-x-3">
			<Logo wrapperProps={{ classes: 'max-tablet:hidden', height: 'h-8', width: 'w-10' }} />
			<span class="h-fit tablet:mt-1"> You must be logged in to submit a post. </span>
		</p>
		<button
			type="button"
			class="variant-filled-surface btn px-6"
			on:click={handleUnauthorizedClick}
		>
			<span> Join now </span>
		</button>
	</div>
</SignedOut>

<!-- FORM -->
<Form
	id="new-post-form"
	gap="gap-y-0"
	{padding}
	on:submit={handleSubmitClick}
	on:change={() => saveDraft(activeDraft)}
>
	<!-- MESSAGE -->
	{#if withMessage}
		<div class="mb-4">
			<p class="mb-2 font-bold">
				Join the <span class="text-primary-500">conversation</span>!
			</p>
			<p class="">
				Connect with your fellow investors by sharing your current thoughts on the market.
			</p>
		</div>
	{/if}

	<!-- LOADING OVERLAY -->
	{#if isLoading}
		<LoadingOverlay />
	{/if}

	<!-- VIEWS -->
	<div class="flex h-full w-full grow flex-col gap-y-2">
		<div class="relative min-h-[430px] overflow-hidden {editorClasses}">
			{#if view === 'DRAFTS'}
				<DraftList
					{drafts}
					{updateActiveDraft}
					bind:view
					on:deleteDraft={(e) => deleteDraft(e.detail.draftId)}
				/>
			{:else}
				<NewPostEditor
					{errorMessage}
					{focusOnMount}
					bind:activeDraft
					bind:view
					on:saveForLater={saveForLater}
				>
					<svelte:fragment slot="actions-trail">
						<SubmitButton disabled={false} bind:isLoading on:click={handleSubmitClick}>
							<span> Post </span>
						</SubmitButton>
					</svelte:fragment>
				</NewPostEditor>
			{/if}
		</div>
	</div>
</Form>
