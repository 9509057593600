import type { SupabaseClient } from '@supabase/supabase-js';
import type { Result } from 'ts-results-es';

import { logger } from '@tickrr/lib/logger';
import { Err, Ok } from 'ts-results-es';

import type { DBQueryError } from '../../types';

/**
 * Uploads a feed item image (POSTs only) to Supabase storage.
 *
 * @param feedItemId - The ID of the feed item.
 * @param imgUrl - The URL of the image to upload.
 * @param supabase - The Supabase client instance.
 * @returns A promise that resolves with the file path of the uploaded image.
 * @throws If there is an error fetching or uploading the image.
 */
export async function uploadPostImageToSupabase({
	feedItemId,
	file,
	supabase
}: {
	feedItemId: string;
	file: File | string;
	supabase: SupabaseClient;
}): Promise<Result<{ imagePath: string }, DBQueryError>> {
	try {
		// Throw if no image provided.
		// Attempt to upload image to Storage.
		const filePath = `${feedItemId}`;
		const { error } = await supabase.storage.from('feed-items').upload(filePath, file, {
			upsert: false
		});

		// Catch possible errors (e.g., URL already exists).
		if (error) {
			logger.error({ error }, `Error uploading image for post ${feedItemId}.`);
			throw error;
		}

		// Return URL so client-side data can fetch image and update.
		return Ok({ imagePath: filePath });
	} catch (e) {
		logger.error(e);
		return Err<DBQueryError>({
			code: 'UNEXPECTED_ERROR',
			message: 'Error uploading image to Supabase.'
		});
	}
}
