import type { DrawerSettings, DrawerStore, ModalSettings } from '@skeletonlabs/skeleton';

import type { ModalStore } from '../../../../types';

import { defaultDrawerSettings } from '../../elements/drawer/defaultSettings';
import NewPostModal from './_internal/NewPostModal.svelte';

/**
 * Opens the new post component, either as a modal or a drawer depending on the device.
 * @param drawerStore - The drawer store.
 * @param isMobile - Whether the device is mobile or not.
 * @param modalStore - The modal store.
 */
export const openNewPostComponent = ({
	drawerStore,
	// isExtension,
	isMobile,
	modalStore
}: {
	drawerStore: DrawerStore;
	isExtension: boolean;
	isMobile: boolean;
	modalStore: ModalStore;
}) => {
	if (isMobile) {
		drawerStore.open({ ...newPostDrawerSettings });
		return;
	}

	// if (isExtension) {
	openNewPostModal(modalStore);
	// 	return;
	// }

	// goto('/new?t=post');
};

const newPostModalSettings: ModalSettings = {
	component: {
		ref: NewPostModal
	},
	type: 'component'
};

const openNewPostModal = (modalStore: ModalStore) => {
	modalStore.trigger({ ...newPostModalSettings });
};

/**
 * Drawer Settings.
 */
export const newPostDrawerSettings: DrawerSettings = {
	...defaultDrawerSettings.bottom,
	id: 'new-post-drawer'
};
