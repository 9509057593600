<!-- 
    @component
    
    IMAGE UPLOADER
    
    Allows users to upload images. Specifically, used for 
    posts and comments.

-->
<script lang="ts">
	export let file: File | null = null;

	let files: FileList | null = null;
	let fileUploadInput: HTMLInputElement | null = null;

	function openFileUploadInput() {
		fileUploadInput?.click();
	}

	function deleteFile() {
		files = null;
		file = null;
	}

	$: file = files ? files[0] : null;
	$: imgData = file ? URL.createObjectURL(file) : null;
</script>

<div data-testid="image-uploader">
	<!-- UPLOAD TRIGGER -->
	<button
		data-testid="image-uploader__trigger"
		type="button"
		class="btn relative flex h-32 w-60 items-center justify-center overflow-hidden bg-surface-700 !p-0 ring-2 ring-base rounded-container-token hover:ring-hover"
		on:click={openFileUploadInput}
	>
		{#if !imgData}
			<iconify-icon icon="solar:camera-bold"></iconify-icon>
			<span> Upload image </span>
		{:else}
			<img
				alt="Post thumbnail"
				data-testid="image-uploader__preview"
				src={imgData}
				class="h-full w-full"
			/>
			<button
				type="button"
				class="btn-icon btn-icon-sm absolute right-1 top-1 rounded-full hover:text-red-500"
				on:click|stopPropagation={deleteFile}
			>
				<iconify-icon icon="solar:close-circle-bold" />
			</button>
		{/if}
	</button>

	<!-- UPLOAD INPUT (HIDDEN) -->
	<input
		bind:this={fileUploadInput}
		id="single"
		accept="image/*"
		data-testid="image-uploader__input"
		type="file"
		class="hidden"
		bind:files
	/>
</div>
